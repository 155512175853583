import React, {  useCallback, useEffect, useState } from 'react'
import { View, StyleSheet, Text } from "@react-pdf/renderer"
import Column from '../../../../../../../../components/PDF/Column'
import { HistoricoColetaElement } from '../../../../Types/HistoricoColeta'
import _ from 'lodash'



const TableRow: React.FC<{flagMostrar:any,naoConforme:{data:string, maximo:string, encontrado:string}[]}>  = ({naoConforme, flagMostrar}) => {
    //const [coletas, setColetas] = useState<HistoricoColetaElement[] | null>(null)

    const [coletas1, setColetas1] = useState<HistoricoColetaElement[]>([])
    const [coletas2, setColetas2] = useState<HistoricoColetaElement[]>([])
    const [coletas3, setColetas3] = useState<HistoricoColetaElement[]>([])
    // const [ids, setIds] = useState<number[] | null>(null)
    // const [idsDbo, setIdsDbo] = useState<number[] | null>(null)
    const [ponto, setPonto] = useState<any[] | null>(null)
    const [max, setMax]  = useState<number | null>(null)
    const [ultimaData, setUltimaData]  = useState<string | null>(null)
    //const [coletaReferencia, setColetaReferencia] = useState<any | null>(null)

   // const [valoresInclusos, setvaloresInclusos]  = useState<number[]>([])
    // const [parametros, setParametros] = useState<any[] | null>(null)
    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },       
         container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },        
        column: {
            padding: '2px 2px',
            fontSize: '8px',
            fontfamily: 'Arial',
            textAlign: 'center',
        },
        contarinerDbo:{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        }
    })
    
    const handleValue = (value: string | number) => {
        if (typeof value === 'number') return value === 0 ? '-' : value.toString().replace('.', ',')
        if (typeof value === 'string') return value.length === 0  || Number(value)=== 0? '-' : value.replace('.', ',')
        return value ?? '-'
    }

    useEffect(() => {
  
        var col: HistoricoColetaElement[] = JSON.parse(localStorage.getItem('HistoricoColeta')!)
 
        const ultimaCoelta: {data:string} = JSON.parse(localStorage.getItem('ultimaColeta')!)

        let anoAtras: any = null
            anoAtras = new Date(new Date((new Date(ultimaCoelta?.data).getTime() - (1.08e7 * 8 * 365))).toDateString()) 
            setUltimaData(ultimaCoelta?.data)
        const resultadosAano = col.filter(x=> new Date(x.dtInstalaco) >= anoAtras && (x.idTipoDBO===1 || x.idTipoDBO===4) ).map(i => (i.id))
        let valoresParaMostrar=null

        if(resultadosAano.length<3){

            //.filter(x=> x.DBO>0 && (x.idTipoDBO===1|| x.idTipoDBO===4))
            if(col.filter(x=> x.DBO>0 && (x.idTipoDBO===1|| x.idTipoDBO===4)).length>=3){
                console.log(col)
                valoresParaMostrar = col.filter(x=> x.DBO>0 && (x.idTipoDBO===1|| x.idTipoDBO===4)).at(-3)
            }else{
                valoresParaMostrar = col.filter(x=> x.DBO>0 && (x.idTipoDBO===1 || x.idTipoDBO===4))[0]
            }

        }else{
            valoresParaMostrar = col.filter(x=> new Date(x.dtInstalaco) >= anoAtras && (x.idTipoDBO===1 || x.idTipoDBO===4) )[0]
        }



        const coletasDiferentedeZero = col.filter(x=> x.DBO>0 && x.idTipoDBO===1)

        let maxProp = 0

        if(col.filter(item=> (resultadosAano  && resultadosAano?.length>2? (resultadosAano?.includes(item.id)) : (item.id === col.at(-1)?.id || item.id === col.at(-2)?.id || item.id === col.at(-3)?.id))).length>2){
            const valoresSoma = col.filter(item=> item.DBO>0 && (resultadosAano?.length>2? (resultadosAano?.includes(item.id)) : (item.id === coletasDiferentedeZero.at(-1)?.id || item.id === coletasDiferentedeZero.at(-2)?.id || item.id === coletasDiferentedeZero.at(-3)?.id))).map(I=> I.DBO)
            const valoresAdicionais = col.filter(x => x.idTipoDBO === 4).map(i=> i.DBO)

            setMax([...valoresSoma, ...valoresAdicionais].length)
            maxProp = [...valoresSoma, ...valoresAdicionais].length

        }else{
            setMax(col.length)
            maxProp = col.length
        }
        
        const coletasPro = col.filter(x=> x.idTipoDBO !== 5 && (x.DBO>0 || x.idTipoDBO===2 || x.idTipoDBO===3 || x.idTipoDBO===4 ))
        
        const coletaReferenciaProp = valoresParaMostrar

        if(coletaReferenciaProp){
        const coletasParaIR = coletasPro.filter(item=> new Date(item?.dtInstalaco) >= new Date(coletaReferenciaProp?.dtInstalaco) || item.idTipoDBO===4)

        let index =0 
        const ids :number[] = []
        let props : any [] = []
        for (const iterator of coletasParaIR) {
            if(index+1 <= Number((maxProp!/3).toFixed(0))){
                props.push(iterator)
                ids.push(iterator.id)
            }
            index++
        }

        setColetas1(props)

        index=0
        props = []
        for (const iterator of coletasParaIR.filter(x=> !ids.includes(x.id))) {
  
            if(index+1 <= Number((maxProp!/3).toFixed(0))){
                props.push(iterator)
                ids.push(iterator.id)
            }
            index++
        }

        setColetas2(props)

        index=0
        props = []

        for (const iterator of coletasParaIR.filter(x=> !ids.includes(x.id))) {
            props.push(iterator)
            ids.push(iterator.id)
            
            index++
        }

        setColetas3(props)


    }

        //setIds(resultadosAano)
        //let valuesDBO = [col.filter(x=> x.DBO>0).at(-1)?.id!, col.filter(x=> x.DBO>0).at(-2)?.id!, col.filter(x=> x.DBO>0).at(-1)?.id!]
        

        //const soma =_.sum(col.filter(item=> item.DBO>0 && (resultadosAano?.length>2? (resultadosAano?.includes(item.id)) : (item.id === coletasDiferentedeZero.at(-1)?.id || item.id === coletasDiferentedeZero.at(-2)?.id || item.id === coletasDiferentedeZero.at(-3)?.id))).map(I=> I.DBO))
        //const resultado = soma/ col.filter(item=> item.DBO>0 && item.idTipoDBO===1 && (resultadosAano?.length>2? (resultadosAano?.includes(item.id)) : (item.id === coletasDiferentedeZero.at(-1)?.id || item.id === coletasDiferentedeZero.at(-2)?.id || item.id === coletasDiferentedeZero.at(-3)?.id))).map(I=> I.DBO).length
        //localStorage.setItem("resultadoSoma", `${resultado/1000}`)
        
        // setIdsDbo([ ])
        function mudaPonto(valor: string){

            const primeiraLetra = valor[0]
            const isNotNumber = isNaN(Number(primeiraLetra))
            if(isNotNumber){
                return valor?.trim() ?? " " 
            }

            valor = valor?.replaceAll('0', '')
            valor = valor?.replaceAll('1', '')
            valor = valor?.replaceAll('2', '')
            valor = valor?.replaceAll('3', '')
            valor = valor?.replaceAll('4', '')
            valor = valor?.replaceAll('5', '')
            valor = valor?.replaceAll('6', '')
            valor = valor?.replaceAll('7', '')
            valor = valor?.replaceAll('8', '')
            valor = valor?.replaceAll('9', '')
            valor = valor?.replaceAll('.', '')
            return valor?.trim()
        }

        let pontos : string[] = JSON.parse(localStorage.getItem('valorPonto')!)
        pontos = _.uniq(pontos.map(i=> mudaPonto(i)))
        console.log(pontos)
        const pontosIndexidados = pontos.map((item,index) => (
            {
                i:index+1,
                valor: item
            }
        ))
        setPonto(pontosIndexidados)

        // setParametros(JSON.parse(localStorage.getItem("Parametro")!))
   }, [flagMostrar])


   const mudaPonto = useCallback( (valor: string ) => {

    const primeiraLetra = valor[0]
    const isNotNumber = isNaN(Number(primeiraLetra))
    if(isNotNumber){
        return valor?.trim() ?? " " 
    }    
    valor = valor?.replaceAll('0', '')
    valor = valor?.replaceAll('1', '')
    valor = valor?.replaceAll('2', '')
    valor = valor?.replaceAll('3', '')
    valor = valor?.replaceAll('4', '')
    valor = valor?.replaceAll('5', '')
    valor = valor?.replaceAll('6', '')
    valor = valor?.replaceAll('7', '')
    valor = valor?.replaceAll('8', '')
    valor = valor?.replaceAll('9', '')
    valor = valor?.replaceAll('.', '')
    return valor?.trim()
}, [])

    return(
<>

<View style={styles.contarinerDbo}>
<View  style = {styles.container} >
        <View style={styles.row}>
            <Column text = {"Coletas que Compõem a média"} width = {max? "30%": "10%"} />
            <Column text = {"P"} width = {max? "12%": "4%"} />
            <Column text = {"DBO mg/L"}  width = {max? "30%": "10%"} last />
            </View>
            {coletas1.map((item, index) =>(
            
           <View id={item.id?.toString()} style={styles.row} wrap={false} >
            <Column text = {`${item.dtInstalaco && new Date(item?.dtInstalaco).toLocaleDateString()}`} width = {max? "30%": "10%"} />
            <Column text = {`${ponto!.find(x=> x.valor=== mudaPonto(item.pontoColeta))?.i}`} width = {max? "12%": "4%"} />
            <Column text = {`${handleValue(item.DBO)}`} Color={`${item.idTipoDBO===1 || item.idTipoDBO===4 ?"#FDA5E4":item.idTipoDBO===2?"#9BF45F":"#519FF9" }`} width = {max? "30%": "10%"} last />
           
            </View>

            ))}

        </View>

        {coletas2?.length>0 && 
        <View  style = {styles.container} >
        <View style={styles.row}>
            <Column text = {"Coletas que Compõem a média"} width = {max? "30%": "10%"} />
            <Column text = {"P"} width = {max? "12%": "4%"} />
            <Column text = {"DBO mg/L"}  width = {max? "30%": "10%"} last />
            </View>
            {coletas2.map((item, index) =>(
            
            <View style={styles.row} wrap={false} >
            <Column text = {`${item.dtInstalaco && new Date(item.dtInstalaco).toLocaleDateString()}`} width = {max? "30%": "10%"} />
            <Column text = {`${ponto!.find(x=> x.valor=== mudaPonto(item.pontoColeta))?.i}`} width = {max? "12%": "4%"} />
            <Column text = {`${handleValue(item.DBO)}`} Color={`${item.idTipoDBO===1 || item.idTipoDBO===4 ?"#FDA5E4":item.idTipoDBO===2?"#9BF45F":"#519FF9" }`} width = {max? "30%": "10%"} last />
            </View>
            ))}

        </View>
        }

        {coletas3?.length>0 &&   <View  style = {styles.container} >
        <View style={styles.row}>
            <Column text = {"Coletas que Compõem a média"} width = {"30%"} />
            <Column text = {"P"} width = "12%" />
            <Column text = {"DBO mg/L"}  width = "30%" last />
            </View>
            {(coletas3).map((item) =>(
            
            <View style={styles.row} wrap={false} >
            <Column text = {`${item.dtInstalaco && new Date(item?.dtInstalaco).toLocaleDateString()}`} width = "30%" />
            <Column text = {`${ponto!.find(x=> x.valor=== mudaPonto(item.pontoColeta))?.i}`} width = "12%" />
            <Column text = {`${handleValue(item.DBO)}`} Color={`${item.idTipoDBO===1 || item.idTipoDBO===4 ?"#FDA5E4":item.idTipoDBO===2?"#9BF45F":"#519FF9" }`} width = {max? "30%": "10%"} last />
            </View>
            ))}

        </View>}
</View>

        

        <View style = {{marginBottom: '30px'}} />

            <View style = {styles.container} >
            <View style={styles.row}>

                <Column text = {"Não conformidades nos últimos 12 meses"} width = "30%" last />
            </View>
                <View style={styles.row}  wrap={false}>
                    <Column text = {"Data Coleta"} width = "10%" />
                    <Column text = {"Valor máximo"} width = "10%" />
                    <Column text = {"Valor Encontrado"} width = "10%" last />
                </View>

                {naoConforme.filter(x=> new Date(x.data) >= new Date((new Date(ultimaData!).getTime() - (1.08e7 * 8 * 365))) ).map(item=>(

             <View style={styles.row} wrap={false}>
               <Column text = {`${new Date(item.data).toLocaleDateString()}`} width = "10%" />
               <Column text = {`${item.maximo?.replaceAll(".",",")}`} width = "10%" />
               <Column Color="red" text = {`${`${item.encontrado}`.replace(".",",")}`} width = "10%" last />
           </View>
                ))}


                <View style={styles.row}  wrap={false}>

                    <Column text = {"Valores Máximos Permitidos de acordo com o Artigo 19-A do decreto 8468/76. Unidades em mg/L, para o parâmetro SS mL/L"} width = "30%" last />
                </View>
            </View>

            <View style = {{marginBottom: '40px'}} />

            <View style = {[styles.container, {marginTop: '20px'}]} wrap={false} break>
                <View style={styles.row}>
                    <View style={[styles.column, {backgroundColor:'#9BF45F'}]}>
                        <Text>{"    "}</Text>
                    </View>
                    <Text style={{fontSize: '8px', marginLeft:"4px" }}> Erro de análise</Text>
                </View>
                <View style={styles.row}>
                <View style={[styles.column, {backgroundColor:'#519FF9'}]}>
                        <Text>{"    "}</Text>
                    </View>
                    <Text style={{fontSize: '8px', marginLeft:"4px" }}> Coleta não representativa</Text>
                </View>
                <View style={styles.row}>
                <View style={[styles.column, {backgroundColor:'#FDA5E4'}]}>
                        <Text>{"    "}</Text>
                    </View>
                    <Text style={{fontSize: '8px', marginLeft:"4px" }}> Valores considerados para DBO de cobrança</Text>
                </View>
                <View style={styles.row}>
                <View style={[styles.column, {backgroundColor:'red'}]}>
                        <Text>{"    "}</Text>
                    </View>
                    <Text style={{fontSize: '8px', marginLeft:"4px" }}> Resultado não conforme</Text>
                </View>
                <Text style={{fontSize: '8px', marginTop:'12px' }}> Os resultados dos ensaios realizados no último monitoramento encontram-se anexo.</Text>
            </View>


</>

    )

}

export default TableRow