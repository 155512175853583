import React from 'react'
import { Field } from 'formik'
import CurrencyInput from 'react-currency-input-field'
import { ListInputProps } from '../types'

const ListInput: React.FC <ListInputProps> = ({ inputID, inputName, inputType, inputMask, setFieldValue, value, onChange, dataRegistro, onClick }) => {

    const inputProps = {
        id: inputID,
        name: inputName,
        type: inputType ?? 'text',
    }

    return(

        <>
            {(inputMask === 'currency' && (
                <CurrencyInput 
                    {...inputProps} 
                    decimalSeparator=","
                    groupSeparator="."
                    value={value}
                    onClick ={t => {if(onClick){
                        
                        onClick(t)
                    }} }
                    onValueChange={text =>setFieldValue!(inputName, text) }
                    onChange={t => {
                        if(onChange)
                            onChange(dataRegistro)
                        
                    }}
                />
            )) || <Field {...inputProps} />}
        </>

    )

}

export default ListInput