import React, { Fragment, useCallback } from 'react'
import Icon, { Icon as MdiIcon } from '@mdi/react'
import { mdiRefresh , mdiCheck, mdiClose, mdiFilePdfBox, mdiMicrosoftExcel, mdiProgressDownload, mdiPencil, mdiDelete, mdiContentSave } from '@mdi/js'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { HistoricoColeta, HistoricoColetaElement } from '../Types/HistoricoColeta'
import Section, { SectionBox, SectionOption } from '../../../../../components/Section'
import { generateHistoricoExcel } from '../scripts/generateHistoricoExcel'
import { Link, useParams } from 'react-router-dom'
import conformidade from '../scripts/conformidade'
import {MenuItem, Select as SelectMUI, TextField } from '@mui/material'
import { useAppSelector } from '../../../../../store/hooks'
import { setListaColetas, setListaCor } from '../Reducers/ClientsReducer'
import { useDispatch } from 'react-redux'
import saveColor from '../scripts/saveColor'
import getHistoricoColeta from '../scripts/getHistoricoColeta'
import { Button } from '@material-ui/core' 
import coletaEspecifica from '../scripts/coletaEspecifica'
import Modal from '../../../../../components/Modal'
import { CircleSpinner } from 'react-spinners-kit'
import isEditable from '../../../../../scripts/modules/isEditable'

interface Props {
    id: number
    lista: HistoricoColeta | null
    loading: boolean
    setEdit: Function
    setValues: Function
    setRemove: Function
  }

const TableListarHistoricoColeta: React.FC<Props> = ({ id, lista, loading, setEdit, setValues, setRemove }) => {


    const { accessAuthorizedModules } = useAppSelector(state => state.modules)
    const canEdit = isEditable(accessAuthorizedModules, 'ignore_edicao-amostra')


    const { idCliente } = useParams<any>()
    const [selectedLine, setSelectedLine] = React.useState<HistoricoColetaElement | null>(null)
    const [novasColetas, setNovasColetas] = React.useState<any[]>([])
    const [openNova, setOpenNova] = React.useState<boolean>(false)
    const [loadingNovo, setLoadingNovo] = React.useState<boolean>(false)
    const SHOW_TABLE = !!lista && !loading
    const parametros = ['DQO', 'DBO', 'pH', 'temperatura', 'Ag', 'As_', 'Cd', 'CN', 'Cr', 'Cr6', 'Cu', 'Fe', 'Fenol', 'Hg', 'Ni', 'N', 'Pb', 'P', 'Se', 'Sn', 'Zn', 'Rs', 'Og', 'Sulfato', 'Sulfeto', 'Fluoreto', 'Toxicidade']
    const { cor, coletas, idCor, valorDBOAtual, valorDBOAnterior } = useAppSelector(state => state.ClientsReducer)
    const dispatch = useDispatch()


    const handleEdit = useCallback((values: any) => {
    setEdit(true)
    setValues(values)
    }, [setEdit, setValues])

    const handleRemove = useCallback((values: any) => {
        setRemove(true)
        setValues(values)
        }, [setValues, setRemove])

    const handleValue = (value: string | number) => {
        if (typeof value === 'number') return value === 0 ? '-' : value.toString().replace('.', ',')
        if (typeof value === 'string') return value.length === 0  || Number(value)=== 0? '-' : value.replace('.', ',')
        return value ?? '-'
    }

    const handleDate = (date: Date) => {
        const novaData = new Date(date.toISOString().replace(/\.\d{3}Z$/, ''))
        return new Date((novaData.getTime() - (1.08e7))).toLocaleString()
    }
    const handleUnidade = (value: string ) => {
        let unidadeNova = ''
        if (value.toUpperCase() === 'PH' || value.toUpperCase() === '°C' || value.toUpperCase() === 'TOXICIDADE') unidadeNova =  value
        else if(value.toUpperCase()==="SS") unidadeNova = `${value} ml/L`
        else unidadeNova = `${value} mg/L`
        if (!(value.toUpperCase() === 'PH' || value.toUpperCase() === '°C' || value.toUpperCase() === 'TOXICIDADE' || value.toUpperCase() === 'SS' || value.toUpperCase() === 'OG' || value.toUpperCase() === 'SULFETO' || value.toUpperCase() === 'SULFATO' || value.toUpperCase() === 'FENOL' 
        || value.toUpperCase() === 'FLUORETO' || value.toUpperCase() === 'CR6' || value.toUpperCase() === 'CN' )) unidadeNova = `${unidadeNova} *`
        return unidadeNova
    }

    const handleFormat = (valor: string ) => {
        if(valor==="-" || valor==="ND")return valor
        if(valor.includes('<')){
            valor = valor.replaceAll('<', '')
            const newValor = Number(valor.replace(",",".")).toLocaleString('pt-BR');
            return '<' + newValor
        }
        return Number(valor.replace(",",".")).toLocaleString('pt-BR');
    }

    const handleSave = useCallback(async () => {
        //lista.result
        let ArraydeIds = coletas?.replaceAll(' ', '')?.split(",")!
        console.log(ArraydeIds)
        let listaID: any[] = []
        lista?.result.forEach((element, index) => {
            if(ArraydeIds.includes(`${index+1}`)){
                listaID.push(`'${element.identificacao}'`)
            }
        });
       const data = {
        id:idCor,
        idPessoa:idCliente,
        valores:coletas,
        cor:cor,
        listaIds: listaID.join(",")
       }
       await saveColor(dispatch, data)
       
       getHistoricoColeta(dispatch, Number(idCliente))
    }, [dispatch, coletas, cor, idCor, idCliente, lista?.result])

    const handleGetColeta = useCallback(async () => {
        setLoadingNovo(true)
     setNovasColetas([])
      const valor:any[] = await coletaEspecifica(idCliente)
      setNovasColetas(valor!)
      setOpenNova(true)
       getHistoricoColeta(dispatch, Number(idCliente))
       setLoadingNovo(false)
    }, [dispatch, idCliente])

    return (
        <>
                    <Section name = "data">
                <SectionBox 
                    title = {`Cliente`} 
                    padding = {false} 
                    goBack
                    right = {
                        <>
<SectionOption icon = {mdiMicrosoftExcel} tooltip = "Histórico de coletas" onClick = {() => {generateHistoricoExcel(lista!,valorDBOAnterior!,valorDBOAtual!)}} />
                            <Link to = "../historico-coletaSimples/pdf/0" target = "_blank">
                                <SectionOption  icon = {mdiFilePdfBox} tooltip = "Historico de coleta simplificado" onClick = {() => {}} />
                            </Link>
                            <Link to = "../historico-coletaCompleta/pdf/0" target = "_blank">
                                <SectionOption icon = {mdiFilePdfBox} tooltip = "Historico de coleta completo" onClick = {() => {}} />
                            </Link>
                            <Link to = "../historico-coletaCompleta/pdf/1" target = "_blank">
                                <SectionOption icon = {mdiFilePdfBox} tooltip = "Historico de coleta completo 2 anos atras" onClick = {() => {}} />
                            </Link>
                            {!loadingNovo && <SectionOption icon = {mdiProgressDownload} tooltip = "Buscar Coletas" onClick = {() => {handleGetColeta()}} />}
                        {loadingNovo && <CircleSpinner size={20} color="grey" />}
                        </>
                    }>
            {SHOW_TABLE && (
                <table className="min customTable" style={{ marginBottom: 24 }}>
                    <thead>
                        <tr>
                            {['DBO de Cobrança Anterior Kg/m³', 'DBO de Cobrança Atual Kg/m³'].map((item, index) => <th key={index}>{item}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{valorDBOAnterior!<0.3?"0,300":valorDBOAnterior!.toFixed(3).replace('.', ',')}</td>
                            <td>{valorDBOAtual!<0.3?"0,300":valorDBOAtual!.toFixed(3).replace('.', ',')}</td>
                        </tr>
                    </tbody>

                </table>
                
            )}
<div style={{marginBottom:"10px"}}>
        <TextField style={{marginRight:"10px"}} id="filterDescricao" variant="standard" className="fieldFilter" placeholder="1,2,3,4..." value={coletas} onChange={e => dispatch(setListaColetas(e.target.value as string))}  />

        <SelectMUI id="filterAtivo" variant="standard"  value={cor} onChange={e => dispatch(setListaCor(e.target.value as any))}>
                            <MenuItem value={"1"}>Branco (status original)</MenuItem>
                                <MenuItem value={"3"}>Azul (Não representativa)</MenuItem>
                                <MenuItem value={"2"}>Verde (Erro análise)</MenuItem>
                                <MenuItem value={"4"}>Rosa (Cobrança de DBO)</MenuItem>
                                <MenuItem value={"5"}>Branco (retira da média)</MenuItem>
                            </SelectMUI>                     
        {canEdit && <Button className='sectionBoxHeaderOption'  size="small" onClick={handleSave}><Icon path = {mdiRefresh } size = "30px" /> </Button>}
</div>
          <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {['N',...(canEdit ?['Editar','Apagar'] : []) ,'P','Instalação', 'ΔT', 'Retirada', 'Q', 'CM', 'Chuva', 'Leitura Inicial', 'Leitura Final', 'Volume Medido'].map((item, index) => <TableCell key={index}>{item}</TableCell>)}
                            {parametros.map((item, index) => {
                                let label = item
                                if (item === 'temperatura') label = '°C'
                                if (item === 'As_') label = 'As'
                                if (item === 'Rs') label = "SS"
                                return <TableCell key={index}>{handleUnidade(label)}</TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {SHOW_TABLE && lista.result.map((item, index) => (
                            <TableRow key={index} className = {selectedLine?.id === item.id ? 'selected' : ''} onClick = {() => setSelectedLine(item)}>
                                <TableCell>{index + 1}</TableCell>
                                {canEdit && <TableCell>
                                <Button className='sectionBoxHeaderOption'  size="small" onClick={() => handleEdit(item)}><Icon path = {mdiPencil } size = "28px" /> </Button>
                                     </TableCell>}
                                {canEdit && <TableCell>
                                <Button   size="small" onClick={() => handleRemove(item)}>
                                <MdiIcon className={`status ${'error'}`} path={mdiDelete} size="28px" />
                                     </Button>
                                     </TableCell>}
                                <TableCell>{item.pontoColeta}</TableCell>
                                <TableCell>{item.dtInstalaco ? handleDate(new Date(item.dtInstalaco)) : '-'}</TableCell>
                                <TableCell>{handleValue(item.tempoInstalacao)}</TableCell>
                                <TableCell>{item.dtRetirada ? handleDate(new Date(item.dtRetirada)) : '-'}</TableCell>
                                <TableCell>{handleValue(item.qtdeColeta)}</TableCell>
                                <TableCell>{item.codigoAmostra?.trim() === "" || item.codigoAmostra===null ? '-': item.codigoAmostra}</TableCell>
                                <TableCell>
                                    <MdiIcon className={`status ${item.flagChuva === 1 ? 'success' : 'error'}`} path={item.flagChuva === 1 ? mdiCheck : mdiClose} size="24px" />
                                </TableCell>
                                <TableCell>{handleFormat(handleValue(item.medidorInicial))}</TableCell>
                                <TableCell>{handleFormat(handleValue(item.medidorFinal))}</TableCell>
                                <TableCell>{handleFormat(handleValue(item.volumeMedido))}</TableCell>
                                {Object.keys(item).map((key, index) => {
                                    if (parametros.includes(key)) {
                                            if(key==="DQO" || key==="DBO" || key==="Sulfato")
                                                return <TableCell  className={` ${conformidade(key, (item as any)[key], lista.result, item.id, item.idTipoDBO) }`} key={index}>{handleFormat(handleValue((item as any)[key]))}</TableCell>
                                            return <TableCell  className={` ${conformidade(key, (item as any)[key], lista.result, item.id, item.idTipoDBO) }`} key={index}>{handleValue((item as any)[key])}</TableCell>  
                                    }
                                    return <Fragment key = {index} />
                                    
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </TableContainer>


            </SectionBox>
            {canEdit &&
            <Button className='sectionBoxHeaderOption'  size="small" onClick={() => handleEdit(null)}>
                <Icon path = {mdiContentSave} size = "28px" /> Novo regristo de historico</Button>
}
            </Section>

            <Modal width = {`100%`} open = {openNova} setOpen = {setOpenNova} title = "Coletas publicadas que foram encontrada">
            <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {['N', 'P', 'Instalação', 'ΔT', 'Retirada', 'Q', 'CM', 'Chuva', 'Leitura Inicial', 'Leitura Final', 'Volume Medido'].map((item, index) => <TableCell key={index}>{item}</TableCell>)}
                            {parametros.map((item, index) => {
                                let label = item
                                if (item === 'temperatura') label = '°C'
                                if (item === 'As_') label = 'As'
                                if (item === 'Rs') label = "SS"
                                return <TableCell key={index}>{handleUnidade(label)}</TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {novasColetas.length>0 && novasColetas.map((item, index) => (
                            <TableRow key={index} className = {selectedLine?.id === item.id ? 'selected' : ''} onClick = {() => setSelectedLine(item)}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{item.pontoColeta}</TableCell>
                                <TableCell>{item.dtInstalaco ? handleDate(new Date(item.dtInstalaco)) : '-'}</TableCell>
                                <TableCell>{handleValue(item.tempoInstalacao)}</TableCell>
                                <TableCell>{item.dtRetirada ? handleDate(new Date(item.dtRetirada)) : '-'}</TableCell>
                                <TableCell>{handleValue(item.qtdeColeta)}</TableCell>
                                <TableCell>{item.codigoAmostra?.trim() === "" || item.codigoAmostra===null ? '-': item.codigoAmostra}</TableCell>
                                <TableCell>
                                    <MdiIcon className={`status ${item.flagChuva === 1 ? 'success' : 'error'}`} path={item.flagChuva === 1 ? mdiCheck : mdiClose} size="24px" />
                                </TableCell>
                                <TableCell>{handleFormat(handleValue(item.medidorInicial))}</TableCell>
                                <TableCell>{handleFormat(handleValue(item.medidorFinal))}</TableCell>
                                <TableCell>{handleFormat(handleValue(item.volumeMedido))}</TableCell>
                                {Object.keys(item).map((key, index) => {
                                    if (parametros.includes(key)) {
                                            return <TableCell  key={index}>{handleValue((item as any)[key])}</TableCell>  
                                    }
                                    return <Fragment key = {index} />
                                    
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </Modal>
        </>
    )

}

export default TableListarHistoricoColeta