import Swal from 'sweetalert2';

export function limparStorage() {

    localStorage.removeItem('listaLancamentoEnvio')
}

export async function verificaSeListaEstaCheia(registro: string) {


    const storageItem = localStorage.getItem('listaLancamentoEnvio')

    if (!storageItem) return false
    const itensLista = storageItem.split(',')
    if (itensLista.length === 0) return false

    const indexReg = itensLista.findIndex(i => i === registro)

    if (indexReg > -1) itensLista.splice(indexReg, 1)

    const focusedElement = document.activeElement as HTMLInputElement



    await sleep(700)
    if (itensLista.length > 0) {

        const verificarConfirm = await creatAltert(`Existem estes registros alterados para verificar ${itensLista.join(',')}`, 'Deseja salvar?')
        const enviar = verificarConfirm.isConfirmed//window.confirm(`Existem estes registros alterados para verificar ${itensLista.join(',')}\n Deseja Prosseguir?`)
        const lancamento = itensLista[0]
        if (enviar) {
        
                const btn = document.getElementById(`btn-salvar-${lancamento}`) as HTMLInputElement
                btn.click()
                          
        }else{
            retiraDaLista(lancamento)  
        }
    }
    await sleep(1000)


    return itensLista
    //return storageItem.split(',').includes(registro)
}



export function adicionarLista(registro: string) {

    const storageItem = localStorage.getItem('listaLancamentoEnvio')

    if (!storageItem) {
        localStorage.setItem('listaLancamentoEnvio', registro)
        return []
    }

    const listaDeRegistros = storageItem.split(',')
    if (!listaDeRegistros.includes(registro)) listaDeRegistros.push(registro)
    localStorage.setItem('listaLancamentoEnvio', listaDeRegistros.join())
    return []
}


export function retiraDaLista(registro: string) {

    const storageItem = localStorage.getItem('listaLancamentoEnvio')

    if (!storageItem) {
        localStorage.setItem('listaLancamentoEnvio', registro)
        return []
    }

    const listaDeRegistros = storageItem.split(',')

    const indexReg = listaDeRegistros.findIndex(i => i === registro)

    if (indexReg > -1) listaDeRegistros.splice(indexReg, 1)

    localStorage.setItem('listaLancamentoEnvio', listaDeRegistros.join())
    return []
}


async function creatAltert(text: string, title: string) {
    return await Swal.fire({
        title: title,
        text: text,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar'
    });
}
function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}