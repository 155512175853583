import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PageRender from '../../components/PageRender'
import NotFound from '../../pages/NotFound'
import GrupoAcesso from '../../pages/system/Permissoes/GrupoAcesso'
import Grupo from '../../pages/system/Permissoes/GrupoAcesso/Grupo'
import Users from '../../pages/system/Permissoes/Usuario'
import CategoriaCobranca from '../../pages/system/Financeiro/CategoriaCobranca'
import Home from '../../pages/system/Home'
import RamoAtividade from '../../pages/system/Pessoas/RamoAtividade'
import RamoAtividadeData from '../../pages/system/Pessoas/RamoAtividade/RamoAtividadeData'
import Clients from '../../pages/system/Pessoas/Cliente'
import Client from '../../pages/system/Pessoas/Cliente/Client'
import Parametro from '../../pages/system/Pessoas/Parametro'
import FonteAbastecimento from '../../pages/system/Financeiro/FonteAbastecimento'
import Roteiro from '../../pages/system/Pessoas/Roteiro'
import Amostrador from '../../pages/system/Financeiro/Amostrador'
import RamoAtividadePdf from '../../pages/system/Pessoas/RamoAtividade/Components/PDF'
import CategoriaCobrancaPdf from '../../pages/system/Financeiro/CategoriaCobranca/Components/PDF'
import RoteiroPdf from '../../pages/system/Pessoas/Roteiro/Components/PDF'
import FonteAbastecimentoPdf from '../../pages/system/Financeiro/FonteAbastecimento/Components/PDF'
import ParametroPdf from '../../pages/system/Pessoas/Parametro/Components/PDF'
import AmostradorPdf from '../../pages/system/Financeiro/Amostrador/Components/PDF'
import ClientePdf from '../../pages/system/Pessoas/Cliente/Components/PDF'
import VazaoLancamento from '../../pages/system/Pessoas/VazaoLancamento'
import PessoaMedidorVazaoTipo from '../../pages/system/Pessoas/PessoaMedidorVazaoTipo'
import PessoaMedidorVazaoTipoPdf from '../../pages/system/Pessoas/PessoaMedidorVazaoTipo/Components/PDF'
import RelatorioUsuarioTecnico from '../../pages/system/Permissoes/RelatorioUsuarioTecnico'
import RelatorioUsuarioTecnicoPdf from '../../pages/system/Permissoes/RelatorioUsuarioTecnico/Components/PDF'
import DboPdf from '../../pages/system/Pessoas/Cliente/Components/PDF_DBO'
import RelatorioPDF from '../../pages/system/Pessoas/Cliente/Components/PDF_RELATORIO'
import VazaoLancamentoPdf from '../../pages/system/Pessoas/VazaoLancamento/Components/PDF'
import ImportacaoAnalise from '../../pages/system/Importacao/ImportacaoAnalise'
import IndustriasMonitoradas from '../../pages/system/Pessoas/IndustriasMonitoradas'
import IndustriasMonitoradasPDF from '../../pages/system/Pessoas/IndustriasMonitoradas/Components/PDF' 
import FichaPdf from '../../pages/system/Pessoas/Cliente/Components/PDF_FICHA'
import CargaPdf from '../../pages/system/Pessoas/Cliente/Components/PDF_CARGA'
import RelatorioParametro from '../../pages/system/Pessoas/RelatorioParametro'
import MonitoramentoCargas from '../../pages/system/Pessoas/MonitoramentoCargas'
import RelatorioParametroPdf from '../../pages/system/Pessoas/RelatorioParametro/components/PDF'
import RelatorioPessoa from '../../pages/system/Pessoas/RelatorioPessoa'
import RelatorioPessoaPdf from '../../pages/system/Pessoas/RelatorioPessoa/components/PDF'
import RelatorioRanking from '../../pages/system/Pessoas/RelatorioRanking'
import RelatorioRankingPdf from '../../pages/system/Pessoas/RelatorioRanking/components/PDF'
import RelatorioPontoColeta from '../../pages/system/Pessoas/RelatorioPontoColeta'
import RelatorioPontoColetaPdf from '../../pages/system/Pessoas/RelatorioPontoColeta/components/PDF'
import RelatorioRegiao from '../../pages/system/Pessoas/RelatorioRegiao'
import RelatorioRegiaoPdf from '../../pages/system/Pessoas/RelatorioRegiao/Components/PDF'
import AnaliseDBO from '../../pages/system/Pessoas/AnaliseDBO'
import VazaoHistoricos from '../../pages/system/Pessoas/VazaoHistoricos'
import VazaoHistorico from '../../pages/system/Pessoas/VazaoHistoricos/components/Historico'
import HistoricoSimplesPdf from '../../pages/system/Pessoas/Cliente/Components/MonAnual/PDF_SIMPLES'
import HistoricoCompletosPdf from '../../pages/system/Pessoas/Cliente/Components/MonAnual/PDF_COMPLETO'
import RelatorioSequencialRanking from '../../pages/system/Pessoas/RelatorioSequencial'
import RelatorioDbo from '../../pages/system/Pessoas/RelatorioDBO'
import RelatorioDBOPdf from '../../pages/system/Pessoas/RelatorioDBO/Components/PDF'
import LogUser from '../../pages/system/Pessoas/LogUsuario'
import LogUserPDF from '../../pages/system/Pessoas/LogUsuario/Components/PDF'
import ImportacaoMylims from '../../pages/system/Importacao/ImportacaoMylims'
import RelatorioNaoConformidade from '../../pages/system/Pessoas/RelatorioNaoConformidade'
import RelatorioNaoConformidadePDF from '../../pages/system/Pessoas/RelatorioNaoConformidade/Components/PDF'
import Protocolos from '../../pages/system/App/Protocolo'
import TextSettings from '../../pages/system/App/TextSettings'
 
const SystemRoute: React.FC = () => {

    return (

        <Routes>
            <Route index element={<PageRender pageName="System_Home" pageTitle="Simon - Home" renderComponent={<Home />} />}/>

            <Route path="cadastro">
                <Route path="cliente">
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_Cliente" pageTitle="Simon - Clientes" renderComponent={<Clients />} />}/>
                    <Route path=":idCliente" element={<PageRender authID={1} pageName="System_Register_Cliente" pageTitle="Simon - Cliente" renderComponent={<Client />} />}/>

                    <Route path='pdf' element={<ClientePdf />} />
                    <Route path='dbo-pdf' element={<DboPdf />} />
                    <Route path='relatorio-pdf' element={<RelatorioPDF />} />
                    <Route path="historico-coletaSimples/pdf/:flagF" element={<HistoricoSimplesPdf />} />
                    <Route path="historico-coletaCompleta/pdf/:flagLimite" element={<HistoricoCompletosPdf />} />
                </Route>
                <Route path='ramo-atividade'>
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_RamoAtividade" pageTitle="Simon - Ramo de Atividade" renderComponent={<RamoAtividade />} />}/>
                    <Route path=":idRamo" element={<PageRender authID={1} pageName="System_Register_RamoAtividade" pageTitle="Simon - Ramo de Atividade" renderComponent={<RamoAtividadeData />} />}/>
                    <Route path="pdf" element={<RamoAtividadePdf />} />
                </Route>

               
                
                <Route path='fonte-abastecimento'>
                    <Route path="" element={<PageRender authID={1} pageTitle="Simon - Roteiros" renderComponent={<FonteAbastecimento />} />}/>
                    <Route path="pdf" element={<FonteAbastecimentoPdf />} />
                </Route>

                <Route path='roteiro'>
                    <Route path="" element={<PageRender authID={1} pageTitle="Simon - Roteiros" renderComponent={<Roteiro />} />}/>
                    <Route path="pdf" element={<RoteiroPdf />} />
                </Route>

                <Route path="Amostrador" >
                    <Route path='' element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Amostradores" renderComponent={<Amostrador />} />}/>
                    <Route path="pdf" element={<AmostradorPdf />} />
                </Route>

                <Route path="Parametro" >
                    <Route path='' element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Parâmetros" renderComponent={<Parametro />} />}/>
                    <Route path="pdf" element={<ParametroPdf />} />
                </Route>

                <Route path="vazao-lancamento">
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Vazão Lançamento" renderComponent={<VazaoLancamento />} />}/>
                </Route>
            </Route>

            <Route path="medidor-vazao">
                <Route path="historico">
                    <Route path="" element={<PageRender authID={1} pageName="System_Vazao_Historicoss" pageTitle="Simon - Vazão Históricos" renderComponent={<VazaoHistoricos />} />}/>
                    <Route path=":idCliente" element={<PageRender authID={1} pageName="System_Vazao_Historico" pageTitle="Simon - Vazão Histórico" renderComponent={<VazaoHistorico />} />}/>
                </Route>
                <Route path="lancamento">
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Vazão Lançamento" renderComponent={<VazaoLancamento />} />}/>
                    <Route path='ficha-pdf' element={<FichaPdf />} />
                    <Route path="pdf" element={<VazaoLancamentoPdf />} />
                </Route>
                <Route path='categoria-cobranca'>
                    <Route path="" element={<PageRender authID={1} pageTitle="Simon - Categoria de Cobrança" renderComponent={<CategoriaCobranca />} />}/>
                    <Route path="pdf" element={<CategoriaCobrancaPdf />} />
                </Route>
                <Route path='pessoa-medidor-vazao-tipo'>
                    <Route path="" element={<PageRender authID={1} pageTitle="Simon - Tipos de Medidores de Vazão" renderComponent={<PessoaMedidorVazaoTipo />} />}/>
                    <Route path="pdf" element={<PessoaMedidorVazaoTipoPdf />} />
                </Route>
            </Route>

            <Route path="relatorio">
            <Route path="Importacao-analise" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Importação de analises" renderComponent={<ImportacaoAnalise />} />}/>
            <Route path="Importacao-mylims" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Importação de analises" renderComponent={<ImportacaoMylims />} />}/>
            <Route path="relatorioDBO">
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Relatório de Atualização de DBO" renderComponent={<RelatorioDbo />} />}/>
                    
                    <Route path='pdf' element={<RelatorioDBOPdf />} />
                </Route>
                <Route path="regiao">
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Relatório de Região" renderComponent={<RelatorioRegiao />} />}/>
                    
                    <Route path='pdf' element={<RelatorioRegiaoPdf />} />
                </Route>
                <Route path="Sequencial" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Relatório Sequencial" renderComponent={<RelatorioSequencialRanking />} />}/>
                <Route path="monitoramento-carga">
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - monitoramento carga" renderComponent={<MonitoramentoCargas />} />}/>
                    <Route path='pdf' element={<CargaPdf />} />
                </Route>
                <Route path="usuario-tecnico">
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Relatório de Técnicos" renderComponent={<RelatorioUsuarioTecnico />} />}/>
                    <Route path='pdf' element={<RelatorioUsuarioTecnicoPdf />} />
                </Route>
                <Route path="industria-monitorada">
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Monitoramento de Indústrias" renderComponent={<IndustriasMonitoradas />} />}/>
                    <Route path="pdf" element={<IndustriasMonitoradasPDF />} />
                </Route>
                <Route path="parametro-analisado">
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Relatório de Parametros" renderComponent={<RelatorioParametro />} />}/>
                    <Route path="pdf" element={<RelatorioParametroPdf />} />
                </Route>
                <Route path="relatorio-pessoa">
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Relatório de Técnicos" renderComponent={<RelatorioPessoa />} />}/>
                    <Route path='pdf' element={<RelatorioPessoaPdf />} />
                </Route>
                <Route path="ranking">
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Relatório de Ranking" renderComponent={<RelatorioRanking />} />}/>
                    <Route path="pdf" element={<RelatorioRankingPdf />} />
                </Route>
                <Route path="pessoa-ponto-coleta">
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Relatório de Pessoa Coleta" renderComponent={<RelatorioPontoColeta />} />}/>
                    <Route path="pdf" element={<RelatorioPontoColetaPdf />} />
                </Route>
                <Route path="relatorio-valores-dbo">
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Atualização de DBO" renderComponent={<AnaliseDBO />} />}/>
                    <Route path="pdf" element={<DboPdf />} />
                </Route>

                <Route path="nao-conforme">
                    <Route path="" element={<PageRender authID={1} pageName="System_Register_Cobrancas" pageTitle="Simon - Relatório de Não conformidade" renderComponent={<RelatorioNaoConformidade />} />}/>
                    
                    <Route path='pdf' element={<RelatorioNaoConformidadePDF />} />
                </Route>

            </Route>

            <Route path="App">
            <Route path="Protocolo" element={<PageRender authID={4} pageName="System_Config_GrupoAcesso" pageTitle="Simon - Protocolos" renderComponent={<Protocolos />} />}/>
            <Route path="Settings-app" element={<PageRender authID={4} pageName="System_Config_GrupoAcesso" pageTitle="Simon - Configurações do app" renderComponent={<TextSettings />} />}/>
            </Route>
            <Route path="acesso">
                <Route path="grupo-acesso">
                    <Route path="" element={<PageRender authID={3} pageName="System_Config_GrupoAcesso" pageTitle="Simon - Grupos de Acesso" renderComponent={<GrupoAcesso />} />}/>
                    <Route path="grupo/:id" element={<PageRender authID={3} pageName="System_Config_GrupoAcesso_Grupo" pageTitle="Simon - Grupo" renderComponent={<Grupo />} />}/>
                </Route>
                <Route path="usuarios">
                    <Route path="" element={<PageRender authID={4} pageName="System_Config_GrupoAcesso" pageTitle="Simon - Usuários" renderComponent={<Users />} />}/>
                </Route>
                <Route path="LogUsers">
                    <Route path="" element={<PageRender authID={4} pageName="System_Config_GrupoAcesso" pageTitle="Simon - Log de Usuários" renderComponent={<LogUser />} />}/>
                    <Route path="pdf" element={<LogUserPDF />} />
                </Route>
            </Route>

            <Route path="*" element={<NotFound route="system route" />} />
        </Routes>
    )
}

export default SystemRoute