
import React, { useCallback, useState, useEffect, ChangeEvent } from 'react'
import { mdiCalculatorVariant, mdiMagnify, mdiPlusCircle } from '@mdi/js'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import { setFieldDtFim, setFieldDtInicio, setSequencial, setIdCliente, setListaFiltered, setIdSalvar, setListaId, setFieldFiltered, setFlagOrdenar } from './Reducers/RelatorioSequencialReducer'
import { TextField, Checkbox } from '@material-ui/core'
import { Formik, Form as FormikContainer } from 'formik'
import filter from './scripts/filter'
import Modal from '../../../../components/Modal'
import Form from '../../../../components/Form'
import getSequenciais from './scripts/getSequenciais'
import send from './scripts/send'
import remove from './scripts/remove'
import Icon from '@mdi/react'
import { FormControlLabel, Tooltip } from '@mui/material'
import filterSequenciais from './scripts/filterSequenciais'
import getUltimoSequencial from './scripts/getUltimoSequencial'
import AgrupamentoComponent from './Components/AgrupamentoComponent'
import get from '../Parametro/Scripts/get'
import isEditable from '../../../../scripts/modules/isEditable'
import {  Select as SelectMUI, MenuItem } from '@material-ui/core'



const RelatorioSequencialRanking: React.FC = () => {

    const dispatch = useDispatch()
    const {idSalvar, listaId,filteredLista,fieldFiltered ,requestSave ,listaSeq ,requestGet, requestFilter ,fieldDtInicio, fieldDtFim, flagOrdenar}= useAppSelector(state => state.RelatorioSequencialReducer)
    const {userData} = useAppSelector(state => state.auth)
    const [openCriar, setOpenCriar] = useState<boolean>(false)
    const [openRemover, setOpenRemover] = useState<boolean>(false)

    const { accessAuthorizedModules } = useAppSelector(state => state.modules)
    const canEdit = isEditable(accessAuthorizedModules, 'sequencial')

    
    const handleFilter = useCallback(async () => {
        filter(dispatch,{dtInicio:fieldDtInicio, dtFim:fieldDtFim})
    }, [dispatch, fieldDtInicio, fieldDtFim])
 
    const handleSalvar = useCallback(async () => {
        let data: any = null
         data = {
            inicio:fieldDtInicio,
            fim:fieldDtFim,
            sequenciais:filteredLista!.filter(x=> listaId?.includes(x.idCliente)),
            idUsuario:userData?.id
        }
    
        send(dispatch,data)
        setOpenCriar(false)
    }, [dispatch,setOpenCriar ,fieldDtInicio, fieldDtFim, filteredLista, listaId, userData])

    const handleRemover = useCallback(async () => {
        const data = {
            id: idSalvar,
            idUsuario:userData?.id
        }

        remove(dispatch,data)
        setOpenRemover(false)
    }, [dispatch, idSalvar, setOpenRemover, userData])

    const handlegGerarSequencia = useCallback(async () => {
        let sequencias: any[] = [] // [...filteredLista!]//?.filter(x=> listaId?.includes(x.idCliente))

        if(filteredLista===null)return null
        let i = await getUltimoSequencial()!
        let ano = (new Date().getFullYear()) - 2000
        for (let index = 0; index < filteredLista!.length; index++) {
            if(listaId?.includes(filteredLista![index].idCliente)){
                i!++
                sequencias.push({
                    idCliente :filteredLista![index].idCliente, 
                    nomeCliente:  `${filteredLista![index].nomeCliente.split("|")[0]} | ${("0000" + i).slice(-4)}/${ano}`
                })
            }
            else
            {
                sequencias.push({
                    idCliente :filteredLista![index].idCliente, 
                    nomeCliente:  `${filteredLista![index].nomeCliente.split("|")[0]}`
                })
            }

        }
        dispatch(setListaFiltered(sequencias))
    }, [filteredLista, dispatch, listaId])

    useEffect(() => {

        getSequenciais(dispatch)
        get("Parametro", dispatch)
    }, [dispatch, requestSave.data])

    useEffect(() => {
console.log(listaSeq)
    }, [listaSeq])

    const handleFilterSeuquenciais= useCallback(async () => {

        filterSequenciais(dispatch,fieldFiltered, flagOrdenar)
  
    }, [dispatch,  fieldFiltered, flagOrdenar])

    const keyPress =(async (e:any) => {
        if(e.keyCode === 13){
            handleFilter()
         }
    }) 
    const handleEditarCheck = useCallback((Checkbox: ChangeEvent<HTMLInputElement>, idParametro: any) => {
        let novaListaId = [...listaId!]
 
        if(Checkbox.target.checked) { 
            novaListaId?.push(idParametro)
            
        }else{
            const index = listaId?.indexOf(idParametro)
            novaListaId.splice(index!, 1)
            //alert(novaListaId)
        }

        dispatch(setListaId([...novaListaId!]))
        
    }, [listaId,dispatch])
    
    return (
        <>

<Section name = "data">
                <SectionBox 
                    title = {` `} 
                    padding = {false}
                    goBack
                    right={
                        <>
                        <TextField  id="filterDescricao" variant="standard" className="fieldFilter" placeholder="Descrição" value={fieldFiltered} onChange={e => dispatch(setFieldFiltered(e.target.value))} onKeyDown={keyPress}/>
                        <SelectMUI contextMenu='text' id="filterAtivo" value={flagOrdenar} onChange={e => dispatch(setFlagOrdenar(e.target.value as any)) }>
                        <MenuItem value={'0'}>Ordernar normal</MenuItem>
                        <MenuItem value={'1'}>Ordernar por nome</MenuItem>
                        </SelectMUI>
                        <SectionOption icon = {mdiMagnify} tooltip = "Filtrar" onClick = {handleFilterSeuquenciais} />

                            {canEdit && <SectionOption icon = {mdiPlusCircle} tooltip = "Criar" onClick = {() => {setOpenCriar(true);
                            dispatch(setIdSalvar(0))
                            dispatch(setIdCliente(null))
                            dispatch(setFieldDtInicio(''))
                            dispatch(setFieldDtFim(''))
                            dispatch(setSequencial(null))
                            dispatch(setListaFiltered(null))
                            }} />}
                        </>
                    }
                >
                    {(!!listaSeq && listaSeq.length>0 )&&  
                    <div>
                    <AgrupamentoComponent
                        lista = {listaSeq}
                        loading = {requestGet.loading || requestFilter.loading}
                        setOpenEditar={setOpenCriar}
                        setOpenRemover={setOpenRemover}
                        canEdit={canEdit}
                    />
                    </div>
                    }

                </SectionBox>
            </Section>

            <Modal width = {800} open = {openCriar} setOpen = {setOpenCriar} title = "Novo">
            <Formik initialValues={{a:''}} onSubmit={ handleSalvar}>
                        <FormikContainer>
                        <Form.Row columns = {3} grid = "140px 140px 140px">
                        <label htmlFor="inicio">Inicio: </label>
                        <TextField title='inicio' style={{marginRight:"30px"}} id="Inicio"  type={"date"} className="fieldFilter" placeholder="Inicio" onKeyPress={keyPress} defaultValue={fieldDtInicio} onChange={(e)=> dispatch(setFieldDtInicio(e.target.value as string))}  />
                        <label htmlFor="fim">Fim: </label>
                        <TextField id="fim"  type={"date"} className="fieldFilter" placeholder="Fim" onKeyPress={keyPress} defaultValue={fieldDtFim} onChange={(e)=> dispatch(setFieldDtFim(e.target.value as string))}  />
                        <span className='defaut' style={{cursor:"pointer", marginLeft:"10px"}} onClick={handleFilter}>
                        <Tooltip title = {"Buscar empresas"} arrow>
                        <Icon  path = {mdiMagnify} size = "28px" /> 
                        </Tooltip>
                            
                            </span>
                            <span className='defaut' style={{cursor:"pointer", marginLeft:"10px"}} onClick={handlegGerarSequencia}>
                            <Tooltip title="Gerar Sequências" arrow>
                            <Icon  path = {mdiCalculatorVariant} size = "28px" /> 
                            </Tooltip>

                            </span>
                        </Form.Row>
                        <br></br>
                        <Form.Row  columns = {3} grid = "140px 140px 140px">

                        {filteredLista?.map((item: {idCliente:  number ; nomeCliente: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined })=>(
                             
                             <>
                             <FormControlLabel control={<Checkbox checked={listaId?.includes(item.idCliente)}  onChange={(e) => handleEditarCheck(e, item.idCliente)}  />} label={`${item.nomeCliente}`} />
                             <br></br>
                             </>
                        ))}
   

                            
                        </Form.Row>
                        <br></br>

                        <div className = "modalBoxMainActionsContainer">
                <button type = "reset" className = "outline" onClick = {() => setOpenCriar(false)}>Cancelar</button>
                <button type = "submit" className = {`default `}>Salvar</button>
            </div>  
                        </FormikContainer>
            </Formik>
                            
                </Modal>


                <Modal width = {600} open = {openRemover} setOpen = {setOpenRemover} title = "Remover cobrança">
                    <span className = "modalBoxMessage">Deseja remover esse item?</span>
                    <div className = "modalBoxMainActionsContainer">
                        <button className = "outline" onClick = {() => setOpenRemover(false)}>Cancelar</button>
                        <button className = {`default`} onClick = {() => handleRemover()}>Remover</button>
                    </div>
                </Modal>
        </>
    )
}

export default RelatorioSequencialRanking



