import React, {  useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import './custom.css'
import Section, { SectionBox } from '../../../../components/Section'

import ReactQuill from 'react-quill'
import getConfiguracaoApp from './Scripts/get';
import TextField from '@mui/material/TextField';
import saveConfig from './Scripts/saveConfig';



const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['blockquote', 'code-block'],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ['clean']
    ]
  };
  
  const formats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'link', 'image', 'color', 'background',
    'align', 'script', 'code-block'
  ];

const TextSettings: React.FC = () => {

    const dispatch = useDispatch()
    // const { protocolos, requestGet} = useAppSelector(state => state.ProtocoloReducer)
    // const { userData } = useAppSelector(state => state.auth)





    const PAGE_NAME = "Protocolos App"


    useEffect(() => {
        (async () => {await getConfiguracaoApp(setTexto, setEmail)})()
    }, [])  
    const [texto, setTexto] = useState(``);
    const [email, setEmail] = useState(``);
    return (
        <>
            <Section name = "NameSection">
                <SectionBox 
                    title = {PAGE_NAME}
                    padding = {true} >

                        <div >

                        <TextField 
                        autoComplete='false'
                        label="Lista de email padrão de envio para o protocolo (Separa por ;)"
                         fullWidth   id="outlined-sla" placeholder="Descrição" value={email} onChange={e => setEmail(e.target.value as string)} />
                   
                   <br/>
                   <br/>
                    <label> Texto padrão para envio do e-mail</label>
                   <ReactQuill 
        theme="snow" 
        value={texto} 
        onChange={setTexto} 
        style={{ fontFamily: 'Arial' }}
      />
      <br/>
<button className='default' onClick={() => saveConfig({textoPadrao:texto, emailPadrao: email})}>Salvar Configuração</button>
</div>
                </SectionBox>
            </Section>
            <>
            </>
        </>
    )
}

export default TextSettings
