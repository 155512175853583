import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."



export default async function getConfiguracaoApp(setTexto: Function, setEmail: Function) {
    try {

        const endpoint = DOMAIN
        const params = `/configuracaoapp/one`
        const response: RequestResponsePattern<any> = await getApi({ endpoint, params })

        if (response) {

            if (!response.flagErro) {
                setTexto(response.listaResultados.textoPadrao)
                setEmail(response.listaResultados.emailPadrao)

            } else throw new Error(response.listaMensagens[0])
        } else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)

    }
}