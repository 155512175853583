import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi, postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."

export default async function saveConfig(data: any) {
    try {


        const endpoint = `${DOMAIN}/configuracaoapp/save`

        const response: RequestResponsePattern<string[]> = await postApi({ endpoint, data })

        if (response) {

            if (!response.flagErro) {
                return response.listaResultados

            } else throw new Error(response.listaMensagens[0])
        } else throw new Error('Erro na requisição')
    } catch (error: any) {
        return []

    }
}