import React, {  useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'

import Section, { SectionBox } from '../../../../components/Section'
import getProtocolos from './Scripts/get'
import TableListarProtocolo from './Components/TableListarProtocolo'
import AgrupamentoComponent from './Components/AgrupamentoComponent'


const Protocolos: React.FC = () => {

    const dispatch = useDispatch()
    const { protocolos, requestGet} = useAppSelector(state => state.ProtocoloReducer)
    const { userData } = useAppSelector(state => state.auth)



    const PAGE_NAME = "Protocolos App"


    useEffect(() => {
        (async () => {await getProtocolos(dispatch)})()
    }, [dispatch])  


    return (
        <>
            <Section name = "NameSection">
                <SectionBox 
                    title = {PAGE_NAME}
                    padding = {false} >
                    {protocolos && <AgrupamentoComponent lista={ protocolos} loading = {requestGet.loading} />}
                </SectionBox>
            </Section>
            <>
            </>
        </>
    )
}

export default Protocolos
