import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../../types/Request"
import { IAgrupamentoProtocolo, IProtocolo } from "../Types/IProtocolo"


interface State {
    //Cliente
    requestGet: RequestStatusPattern<RequestResponsePattern<IAgrupamentoProtocolo[]>>
    requestRemove: RequestStatusPattern<RequestResponsePattern<any>>

    protocolos: IAgrupamentoProtocolo[] | null
    currentProtocolo: IProtocolo | null
}

const requestStatusInitial = {
    data: null,
    loading: false,
    error: false,
    message: null,
}

const initialState: State = {
    requestGet: requestStatusInitial,
    requestRemove: requestStatusInitial,
    protocolos: [],
    currentProtocolo: null
}

const ProtocoloReducer = createSlice({
    name: 'ProtocoloReducer',
    initialState,
    reducers: {
        setRequestGetData: (state, action: PayloadAction<RequestResponsePattern<IAgrupamentoProtocolo[]>>) => {
            state.requestGet.data = action.payload
            state.requestGet.loading = false
            state.requestGet.error = false
        },
        setRequestGetLoading: (state) => {
            state.requestGet.loading = true
            state.requestGet.error = false
        },
        setRequestGetError: (state) => {
            state.requestGet.loading = false
            state.requestGet.error = true
        },
        setRequestGetMessage: (state, action: PayloadAction<string>) => {
            state.requestGet.message = action.payload
        },

        setRequestRemoveData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestRemove.data = action.payload
            state.requestRemove.loading = false
            state.requestRemove.error = false
        },
        setRequestRemoveLoading: (state) => {
            state.requestRemove.loading = true
            state.requestRemove.error = false
        },
        setRequestRemoveError: (state) => {
            state.requestRemove.loading = false
            state.requestRemove.error = true
        },
        setRequestRemoveMessage: (state, action: PayloadAction<string>) => {
            state.requestRemove.message = action.payload
        },




        setProtocolos: (state, action: PayloadAction<IAgrupamentoProtocolo[]>) => {
            state.protocolos = action.payload
        }

    }
})

export const {
    setRequestGetData, setRequestGetLoading, setRequestGetError, setRequestGetMessage,
    setRequestRemoveData, setRequestRemoveLoading, setRequestRemoveError, setRequestRemoveMessage,
    setProtocolos 
} = ProtocoloReducer.actions

export default ProtocoloReducer.reducer