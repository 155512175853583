import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useCallback } from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import { IProtocolo } from '../Types/IProtocolo'
import getListaAquivos from '../Scripts/getListaAquivos'
import Modal from '../../../../../components/Modal'
import Icon from '@mdi/react'
import {  mdiCheck, mdiClose, mdiFileImage } from '@mdi/js'

interface Props {
    lista: IProtocolo[]
    loading: boolean
}



const TableListarProtocolo: React.FC <Props> = ({  lista, loading }) => {
    const [fotos, setFotos] = React.useState<string[]>([])
    const [open, setOpen] = React.useState(false)

    const SHOW_LOADING = false 

    const handleShowImage = useCallback(async (protocolo: IProtocolo) => {
        const images64 = await getListaAquivos(protocolo.id)
        setFotos(images64)
        if(images64.length >0) setOpen(true)
    }, [setFotos])

    const handleDate = (date: Date) => {
        const novaData = new Date(date.toISOString().replace(/\.\d{3}Z$/, ''))
        return new Date(novaData).toLocaleDateString()
    }

    return(
        <>
        
        

        <TableContainer sx={{ maxHeight: 640 }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {['ID','Cliente','Ponto','Periodo','Codigo' ,'Leitura inicial', 'Leitura Final','Lacre', 'lat / lng', 'tecnico', 'contato', 'Horimetro','chuva', 'Amostra cancelada', 'Motivo cancelamento', 'NC' ,'pH', 'temperatura', 'ss', '#'].map((item, index) => <TableCell key = {index}>{item}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {SHOW_LOADING && (
                        <TableRow>
                            <TableCell >
                                <div className = "center">
                                    <CircleSpinner size = {32} color = "grey" />
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                    {lista?.map((item, index:any) => 
                    (
//['Cliente','Ponto'
                        <TableRow >
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.nomeCliente??'-'}</TableCell>
                            <TableCell>{item.pontoColeta??'-'}</TableCell>
                            <TableCell>{item.dtInicio? handleDate(new Date(item.dtInicio)) : ''} / {item.dtFim? handleDate(new Date(item.dtFim)) : ''}</TableCell> 
                            <TableCell>{item.atividadeAmostra}</TableCell>
                            <TableCell>{item.medidorInicial??'-'}</TableCell>
                            <TableCell>{item.medidorFinal??'-'}</TableCell>

                            <TableCell>{item.numeroLacre??'-'}</TableCell>
                            <TableCell>{item.latitude} / {item.longitude}</TableCell>
                            <TableCell>{item.tecnico??'-'}</TableCell>
                            <TableCell>{item.contato??'-'}</TableCell>
                            <TableCell>{item.horimetro??'-'}</TableCell>

                            <TableCell>
                            <Icon className={`status ${item.flagChuva ? 'success' : 'error'}`} path={item.flagChuva ? mdiCheck : mdiClose} size="24px" />
             
                                </TableCell>
                            <TableCell>
                            <Icon className={`status ${item.flagAmostraCancelada ? 'success' : 'error'}`} path={item.flagAmostraCancelada ? mdiCheck : mdiClose} size="24px" />
                                </TableCell>

                            <TableCell>{item.motivoCancelamento??'-'}</TableCell>

                            <TableCell>
                            <Icon className={`status ${item.flagNc ? 'success' : 'error'}`} path={item.flagNc ? mdiCheck : mdiClose} size="24px" />
                                </TableCell>
                            <TableCell>{item.pH ??'-'}</TableCell>
                            <TableCell>{item.temperatura??'-'}</TableCell>
                            <TableCell>{item.ss??'-'}</TableCell>

                            <TableCell onClick = {() => handleShowImage(item)}>
                                <Icon className='sectionBoxHeaderOption' path ={mdiFileImage} size = "24px">
                                </Icon>
                                 </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </TableContainer>

        <Modal open={open} setOpen={setOpen} title="Fotos">
        {fotos?.map((item, index:any) => 
                    (
                        <div >
                        <img src={item} alt="Minha Figura" style={{height:200, width:200}}>
                        </img>
                        </div>
                    ))}
            </Modal></>
    )

}

export default TableListarProtocolo