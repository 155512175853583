import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import { Form as FormikContainer, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { MedidorVazao, vazaoLancamento } from '../types'
import { useAppSelector } from '../../../../../store/hooks'
import { setListaVazao } from '../Reducers/VazaoLancamentoReducer'
import Form from '../../../../../components/Form'
import List, { ListColumn, ListRow, ListSection } from '../../../../../components/List'
import TableRow from './TableRow'
import isEditable from '../../../../../scripts/modules/isEditable'

interface Props {
	lista: MedidorVazao[] | null
	listaLancados: vazaoLancamento[] | null
	handleEditar: (data: any, tipoCobranca: string, volumeCorrigido: string, tempoFinal:string) => void
	handleExcluirMedidorLancamento: (idItem: Number | undefined) => void
	handleSalvarMedidorLancamento: (data: any, tipoCobranca: string, volumeCorrigido: string) => void
}

const TableListarVazao: React.FC<Props> = ({ lista, listaLancados, handleEditar, handleSalvarMedidorLancamento, handleExcluirMedidorLancamento }) => {

    const dispatch = useDispatch()
	const { requestSave, requestGet, requestGetCategorias, requestGetHidrometro, editValues, listaVazao, requestFilter, requestMedidorFilter } = useAppSelector(state => state.VazaoLancamentoReducer)

    const { accessAuthorizedModules } = useAppSelector(state => state.modules)
    const canEdit = isEditable(accessAuthorizedModules, 'lancamento')
    
	const grids = "200px 150px 150px repeat(3, 100px) 160px repeat(4, 100px) 132px 132px"
    
	const SHOW_LOADING = requestGet.loading || requestGetCategorias.loading || requestGetHidrometro.loading || requestFilter.loading || requestMedidorFilter.loading || listaVazao?.length === 0
	const SHOW_TABLE = !!listaVazao && !SHOW_LOADING && listaVazao.length>0

	React.useLayoutEffect(() => {
        (async() => {
            dispatch(setListaVazao([]))
            if(!!lista && !!listaLancados){
                let newLista : vazaoLancamento[] = []
                 lista.forEach( element => {
                    const validar = listaLancados?.find(lancados => lancados.idHidrometro === element.id)
                    if(!!validar) newLista.push(validar)
                    else{
                        newLista.push({ 
                            nome: element.cliente,
                            idPessoa: element.idPessoa, 
                            flagMedidorVazao: element.flagMedidorVazao,
                            registro: element.registro, 
                            leituraAnterior: element.leituraAnterior ?? 0, 
                            idCategoriaCobranca: 0, 
                            id: 0, 
                            idHidrometro: element.id, 
                            medidorStatusNome: '', 
                            tempoAnterior: element.tempoAnterior, 
                            tempoFinal: '', 
                            idCalculoHorimetro: element.idCalculoHorimetro,
                            obs: '' ,
                            uc:element.uc,
                            dtCadastro: null
                        })
                    }
                })

                newLista.sort((a, b) => {
                    if(a.nome < b.nome) return -1
                    if(a.nome > b.nome) return 1
                    return 0
                })

                localStorage.removeItem('ultimaCor')
                localStorage.removeItem('ultimaEmpresa')

                newLista = processarDadosComCores(newLista, ['', '#ccc'])

                localStorage.setItem('ListaVazao', JSON.stringify(newLista))
                 dispatch(  setListaVazao(newLista))
            }
        })()
	}, [dispatch, lista, listaLancados, requestSave])


    const processarDadosComCores = (dados: vazaoLancamento[], cores: string[]) => {
        let ultimaCor =  cores[0];
        let ultimaEmpresa =  '';
      
        return dados.map(item => {
          if (item.nome !== ultimaEmpresa) {
            // Alterna a cor se o nome da empresa mudou
            ultimaCor = ultimaCor === cores[0] ? cores[1] : cores[0];
            // Atualiza localStorage com a nova cor e empresa
            localStorage.setItem('ultimaCor', ultimaCor);
            ultimaEmpresa=  item.nome;
          }
          return { ...item, cor: ultimaCor };
        });
      };

	return (
		<>
			<List name="Parametro">
				<ListSection type="header">
					<ListRow grid={grids}>
						{["Cliente", "Registro","UC", "Leitura Anterior", "Leitura Atual", "Volume Medido (m³)", "Tipo Cobrança", "Tempo Anterior", "Tempo Atual", "Tempo Total (hh:mm:ss)", "Volume Corrigido (m³)", canEdit ? "Opções": '', 'Data'].map((column, index) => <ListColumn key={index} text={column} />)}
					</ListRow>
				</ListSection>
				<ListSection type="main">
					{SHOW_LOADING && (
						<ListRow grid='1fr'>
							<ListColumn center><CircleSpinner size={32} color="grey" /></ListColumn>
						</ListRow>
					)}
					{SHOW_TABLE && listaVazao.map((lancamento, index) => (
						<Formik
                        
							key={index}
							initialValues={{ flagMedia: lancamento.flagMedia?? '0',nome: lancamento.nome ?? '', idPessoa: lancamento.idPessoa, idHidrometro: lancamento.idHidrometro ?? '', registro: lancamento.registro ?? '', leituraAnterior: lancamento.leituraAnterior ?? '', leituraAtual: lancamento.leituraAtual ?? '', volumeMedido: lancamento.volumeMedido ?? '', idCategoriaCobranca: lancamento.idCategoriaCobranca ?? '', id: lancamento.id ?? '', tempoAnterior: lancamento.tempoAnterior, tempoAtual: lancamento.tempoAtual, tempoFinal: lancamento.tempoFinal ?? '', volumeCorrigido: lancamento.volumeCorrigido ?? '', logica: lancamento.idCalculoHorimetro ?? '', obs: lancamento.obs??'' }}
							onSubmit={values => values.id === 0 ? handleSalvarMedidorLancamento(values, editValues.tipoCobranca, editValues.volumeCorrigido) : handleEditar(values, editValues.tipoCobranca, editValues.volumeCorrigido, values.tempoFinal)}   
						>
							{({ handleSubmit, setFieldValue, values, initialValues }) => (
                                <FormikContainer>
                                    <Form.Container padding={false}>
                                        <TableRow
                                            grid={grids}
                                            lancamento={lancamento}
                                            values={values}
                                            initialValues = {initialValues}
                                            handleExcluir={handleExcluirMedidorLancamento}
                                            handleSubmit={handleSubmit}
                                            setFieldValue={setFieldValue}
                                        />
                                    </Form.Container>
                                </FormikContainer>
                            )}
						</Formik>
					))}
				</ListSection>
			</List>
		</>
	)

}

export default TableListarVazao