import React, {useState, useCallback, Fragment} from 'react'
import { CircleSpinner } from 'react-spinners-kit'

import TableListarProtocolo from './TableListarProtocolo'
import { IAgrupamentoProtocolo } from '../Types/IProtocolo'

interface Props {
    lista: IAgrupamentoProtocolo[] | null
    loading: boolean

}

const AgrupamentoComponent: React.FC <Props> = ({ lista, loading }) => {



    const SHOW_LOADING = loading
    const SHOW_TABLE = !!lista && !SHOW_LOADING  && lista.length>0
    const [listaIndex, setListaIndex] = useState<number[]>([])

    const handleEditarCheck = useCallback(( index: number) => {
        let novaListaId = [...listaIndex!]
 
         if(!listaIndex.includes(index)) { 
            novaListaId?.push(index)
            
         }
         else{
             const indexLista = listaIndex?.indexOf(index)
             novaListaId.splice(indexLista!, 1)
        //     //alert(novaListaId)
         }

        setListaIndex([...novaListaId!])
        
    }, [listaIndex])

    const handleDate = (date: Date) => {
      const novaData = new Date(date.toISOString().replace(/\.\d{3}Z$/, ''))
      return new Date(novaData).toLocaleDateString()

      //`Período de: ${item.inicio? handleDate(new Date(item.inicio)) : 'SEM PERIODO INICIAL'} até ${item.fim? handleDate(new Date(item.fim)) : 'SEM PERIODO FINAL'}`
  }

  const handleVerifica = (item: IAgrupamentoProtocolo) => {

    if(item.inicio ===null || item.fim === null ) return 'Periodo não informado'
    return `Período de: ${item.inicio? handleDate(new Date(item.inicio)) : 'SEM PERIODO INICIAL'} até ${item.fim? handleDate(new Date(item.fim)) : 'SEM PERIODO FINAL'}`
  }
    return(
      <>
            {SHOW_TABLE && 
            <>
        <div className="container">
        <div className="accordion" id="accordionPanelsStayOpenExample">

        {SHOW_TABLE &&  lista?.map((item, index) =>(
                        <Fragment key = {index}>
    <div className=" sectionBox">
    <h2 className=" sectionBoxHeader" id="panelsStayOpen-headingOne">
      
      <button className={`sectionBoxHeaderOption accordion-button ${listaIndex?.includes(index)? "collapsed":""}`} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded={"true"} aria-controls="panelsStayOpen-collapseOne"
      onClick={() => handleEditarCheck(index)}>
        {handleVerifica(item)}
      </button>
    </h2>

    <div id="panelsStayOpen-collapseOne" className={`accordion-collapse collapse ${lista.length>0&& listaIndex?.includes(index)? "show":""}`} aria-labelledby="panelsStayOpen-headingOne">
      <div className="accordion-body">
      <TableListarProtocolo
              lista = {item.dados}
              loading = {loading}
          />
      </div>
    </div>
  </div>
  </Fragment>
        ))}


        </div>
    </div> </>}
    {
            SHOW_LOADING &&
            <div> 
            <CircleSpinner size = {32} color = "grey" />
            </div>
        }
      
      </>


    )

}

export default AgrupamentoComponent