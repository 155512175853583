export const arrayTabelas = [
    { id: "usuario", descricao: "usuário" },
    { id: "upload_arquivo", descricao: "upload arquivo" },
    { id: "roteiro", descricao: "roteiro" },
    { id: "relatorio_sequencial", descricao: "relatório sequencial" },
    { id: "ramo_atividade_sinonimo", descricao: "ramo atividade sinônimo" },
    { id: "ramo_atividade", descricao: "ramo atividade" },
    { id: "pessoa_ponto_coleta", descricao: "Cliente ponto coleta" },
    { id: "pessoa_medidor_vazao_tipo", descricao: "Cliente medidor vazão tipo" },
    { id: "pessoa_medidor_vazao", descricao: "Cliente medidor vazão" },
    { id: "pessoa_hidrometro_lancamento", descricao: "Cliente hidrômetro lançamento" },
    { id: "pessoa_hidrometro", descricao: "Cliente hidrômetro" },
    { id: "pessoa_endereco", descricao: "Cliente endereço" },
    { id: "pessoa_contato", descricao: "Cliente contato" },
    { id: "pessoa", descricao: "Cliente" },
    { id: "parametro", descricao: "parâmetro" },
    { id: "mon_anual", descricao: "mon anual" },
    { id: "modulo_menu", descricao: "módulo menu" },
    { id: "modulo", descricao: "módulo" },
    { id: "grupo_menu", descricao: "grupo menu" },
    { id: "grupo_acesso", descricao: "grupo acesso" },
    { id: "fonte_abastecimento", descricao: "fonte abastecimento" },
    { id: "categoria_cobranca", descricao: "categoria cobrança" },
    { id: "atualizacao_DBO", descricao: "atualização DBO" }
  ];
  