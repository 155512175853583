import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../../types/Request"
import { CategoriaCobranca } from "../../Cliente/Types/CategoriaCobranca"
import { ClienteType } from "../../Parametro/Types/ClienteType"
import { MedidorVazao, vazaoLancamento } from "../types"

interface State {
    requestGet: RequestStatusPattern<RequestResponsePattern<vazaoLancamento[]>>
    requestGetClient: RequestStatusPattern<RequestResponsePattern<ClienteType[]>>
    requestGetHidrometro: RequestStatusPattern<RequestResponsePattern<MedidorVazao[]>>
    requestGetCategorias: RequestStatusPattern<RequestResponsePattern<CategoriaCobranca[]>>
    requestGetHoraFinal: RequestStatusPattern<RequestResponsePattern<any>>

    requestSave: RequestStatusPattern<RequestResponsePattern<vazaoLancamento>>
    requestEdit: RequestStatusPattern<RequestResponsePattern<vazaoLancamento>>
    requestRemove: RequestStatusPattern<RequestResponsePattern<vazaoLancamento>>

    requestFilter: RequestStatusPattern<RequestResponsePattern<vazaoLancamento[]>>
    requestMedidorFilter: RequestStatusPattern<RequestResponsePattern<MedidorVazao[]>>

    loadingListaVazao: boolean

    lista: vazaoLancamento[] | null
    listaVazao: vazaoLancamento[] | null
    listaClientes: ClienteType[] | null
    listaHidrometros: MedidorVazao[] | null

    filteredLista: vazaoLancamento[] | null
    filteredMedidorLista: MedidorVazao[] | null
    categorias: CategoriaCobranca[] | null
    fieldFiltered: string
    fieldFilteredMes: any
    fieldFilteredFonte: any
    fieldFilteredPossuiMedidor: any
    fieldFilteredAno: number
    currentLancamento: vazaoLancamento | null
    currentCategoria: string,
    editValues: {
        tipoCobranca: string,
        volumeCorrigido: string,
    }
}

const requestStatusInitial = {
    data: null,
    loading: false,
    error: false,
    message: null,
}

const initialState: State = {
    requestGet: requestStatusInitial,
    requestGetClient: requestStatusInitial,
    requestGetHidrometro: requestStatusInitial,
    requestGetCategorias: requestStatusInitial,
    requestGetHoraFinal: requestStatusInitial,

    requestSave: requestStatusInitial,
    requestEdit: requestStatusInitial,
    requestRemove: requestStatusInitial,

    requestFilter: requestStatusInitial,
    requestMedidorFilter: requestStatusInitial,

    loadingListaVazao: true,

    lista: null,
    listaVazao: null,
    filteredLista: [],
    filteredMedidorLista: [],
    categorias: null,
    listaClientes: null,
    listaHidrometros: null,
    fieldFiltered: '',
    fieldFilteredMes: new Date().getMonth() + 1,
    fieldFilteredFonte: 'null',
    fieldFilteredPossuiMedidor: 'null',
    fieldFilteredAno: new Date().getFullYear(),
    currentLancamento: null,
    currentCategoria: '',

    editValues: {
        tipoCobranca: '',
        volumeCorrigido: '',
    },
}

const VazaoLancamentoSlice = createSlice({
    name: 'VazaoLancamento',
    initialState,
    reducers: {
        setRequestGetData: (state, action: PayloadAction<RequestResponsePattern<vazaoLancamento[]>>) => {
            state.requestGet.data = action.payload
            state.requestGet.loading = false
            state.requestGet.error = false
        },
        setRequestGetLoading: (state) => {
            state.requestGet.loading = true
            state.requestGet.error = false
        },
        setRequestGetError: (state) => {
            state.requestGet.loading = false
            state.requestGet.error = true
        },
        setRequestGetMessage: (state, action: PayloadAction<string>) => {
            state.requestGet.message = action.payload
        },

        setRequestGetCategoriaData: (state, action: PayloadAction<RequestResponsePattern<CategoriaCobranca[]>>) => {
            state.requestGetCategorias.data = action.payload
            state.requestGetCategorias.loading = false
            state.requestGetCategorias.error = false
        },
        setRequestGetCategoriaLoading: (state) => {
            state.requestGetCategorias.loading = true
            state.requestGetCategorias.error = false
        },
        setRequestGetCategoriaError: (state) => {
            state.requestGetCategorias.loading = false
            state.requestGetCategorias.error = true
        },
        setRequestGetCategoriaMessage: (state, action: PayloadAction<string>) => {
            state.requestGetCategorias.message = action.payload
        },

        setRequestGetHoraFinalData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestGetHoraFinal.data = action.payload
            state.requestGetHoraFinal.loading = false
            state.requestGetHoraFinal.error = false
        },
        setRequestGetHoraFinalLoading: (state) => {
            state.requestGetHoraFinal.loading = true
            state.requestGetHoraFinal.error = false
        },
        setRequestGetHoraFinalError: (state) => {
            state.requestGetHoraFinal.loading = false
            state.requestGetHoraFinal.error = true
        },
        setRequestGetHoraFinalMessage: (state, action: PayloadAction<string>) => {
            state.requestGetHoraFinal.message = action.payload
        },

        setRequestData: (state, action: PayloadAction<RequestResponsePattern<vazaoLancamento>>) => {
            state.requestSave.data = action.payload
            state.requestSave.loading = false
            state.requestSave.error = false
        },
        setRequestLoading: (state) => {
            state.requestSave.loading = true
            state.requestSave.error = false
        },
        setRequestError: (state) => {
            state.requestSave.loading = false
            state.requestSave.error = true
        },
        setRequestMessage: (state, action: PayloadAction<string>) => {
            state.requestSave.message = action.payload
        },

        setRequestClientGetData: (state, action: PayloadAction<RequestResponsePattern<ClienteType[]>>) => {
            state.requestGetClient.data = action.payload
            state.requestGetClient.loading = false
            state.requestGetClient.error = false
        },
        setRequestClientGetLoading: (state) => {
            state.requestGetClient.loading = true
            state.requestGetClient.error = false
        },
        setRequestClientGetError: (state) => {
            state.requestGetClient.loading = false
            state.requestGetClient.error = true
        },
        setRequestClientGetMessage: (state, action: PayloadAction<string>) => {
            state.requestGetClient.message = action.payload
        },

        setRequestHidrometroGetData: (state, action: PayloadAction<RequestResponsePattern<MedidorVazao[]>>) => {
            state.requestGetHidrometro.data = action.payload
            state.requestGetHidrometro.loading = false
            state.requestGetHidrometro.error = false
        },
        setRequestHidrometroGetLoading: (state) => {
            state.requestGetHidrometro.loading = true
            state.requestGetHidrometro.error = false
        },
        setRequestHidrometroGetError: (state) => {
            state.requestGetHidrometro.loading = false
            state.requestGetHidrometro.error = true
        },
        setRequestHidrometroGetMessage: (state, action: PayloadAction<string>) => {
            state.requestGetHidrometro.message = action.payload
        },

        setRequestEditData: (state, action: PayloadAction<RequestResponsePattern<vazaoLancamento>>) => {
            state.requestEdit.data = action.payload
            state.requestEdit.loading = false
            state.requestEdit.error = false
        },
        setRequestEditLoading: (state) => {
            state.requestEdit.loading = true
            state.requestEdit.error = false
        },
        setRequestEditError: (state) => {
            state.requestEdit.loading = false
            state.requestEdit.error = true
        },
        setRequestEditMessage: (state, action: PayloadAction<string>) => {
            state.requestEdit.message = action.payload
        },

        setRequestRemoveData: (state, action: PayloadAction<RequestResponsePattern<vazaoLancamento>>) => {
            state.requestRemove.data = action.payload
            state.requestRemove.loading = false
            state.requestRemove.error = false
        },
        setRequestRemoveLoading: (state) => {
            state.requestRemove.loading = true
            state.requestRemove.error = false
        },
        setRequestRemoveError: (state) => {
            state.requestRemove.loading = false
            state.requestRemove.error = true
        },
        setRequestRemoveMessage: (state, action: PayloadAction<string>) => {
            state.requestRemove.message = action.payload
        },

        setRequestFilterData: (state, action: PayloadAction<RequestResponsePattern<vazaoLancamento[]>>) => {
            state.requestFilter.data = action.payload
            state.requestFilter.loading = false
            state.requestFilter.error = false
        },
        setRequestFilterLoading: (state) => {
            state.requestFilter.loading = true
            state.requestFilter.error = false
        },
        setRequestFilterError: (state) => {
            state.requestFilter.loading = false
            state.requestFilter.error = true
        },
        setRequestFilterMessage: (state, action: PayloadAction<string>) => {
            state.requestFilter.message = action.payload
        },

        setRequestFilterMedidorData: (state, action: PayloadAction<RequestResponsePattern<MedidorVazao[]>>) => {
            state.requestMedidorFilter.data = action.payload
            state.requestMedidorFilter.loading = false
            state.requestMedidorFilter.error = false
        },
        setRequestFilterMedidorLoading: (state) => {
            state.requestMedidorFilter.loading = true
            state.requestMedidorFilter.error = false
        },
        setRequestFilterMedidorError: (state) => {
            state.requestMedidorFilter.loading = false
            state.requestMedidorFilter.error = true
        },
        setRequestFilterMedidorMessage: (state, action: PayloadAction<string>) => {
            state.requestMedidorFilter.message = action.payload
        },

        setLoadingListaVazao: (state, action: PayloadAction<boolean>) => {
            state.loadingListaVazao = action.payload
        },

        setLista: (state, action: PayloadAction<vazaoLancamento[]>) => {
            state.lista = action.payload
        },
        setListaVazao: (state, action: PayloadAction<vazaoLancamento[]>) => {
            state.listaVazao = action.payload
        },
        setListaClientes: (state, action: PayloadAction<ClienteType[]>) => {
            state.listaClientes = action.payload
        },
        setListaHidrometros: (state, action: PayloadAction<MedidorVazao[]>) => {
            state.listaHidrometros = action.payload
        },
        setListaFiltered: (state, action: PayloadAction<vazaoLancamento[] | null>) => {
            state.filteredLista = action.payload
        },
        setCategorias: (state, action: PayloadAction<CategoriaCobranca[]>) => {
            state.categorias = action.payload
        },
        setListaMedidorFiltered: (state, action: PayloadAction<MedidorVazao[] | null>) => {
            state.filteredMedidorLista = action.payload
        },
        setFieldFiltered: (state, action: PayloadAction<string>) => {
            state.fieldFiltered = action.payload
        },
        setFieldFilteredMes: (state, action: PayloadAction<number>) => {
            state.fieldFilteredMes = action.payload
        },
        setFieldFilteredFonte: (state, action: PayloadAction<number>) => {
            state.fieldFilteredFonte = action.payload
        },
        setFieldFilteredAno: (state, action: PayloadAction<number>) => {
            state.fieldFilteredAno = action.payload
        },
        setCurrentLancamento: (state, action: PayloadAction<vazaoLancamento>) => {
            state.currentLancamento = action.payload
        },
        setCurrentCategoria: (state, action: PayloadAction<string>) => {
            state.currentCategoria = action.payload
        },

        setEditValues: (state, action: PayloadAction<typeof state.editValues>) => {
            state.editValues = action.payload
        },
        setFieldFilteredMedidor: (state, action: PayloadAction<any>) => {
            state.fieldFilteredPossuiMedidor = action.payload
        },
    }
})

export const {
    setRequestGetData, setRequestGetLoading, setRequestGetError, setRequestGetMessage,
    setRequestClientGetData, setRequestClientGetLoading, setRequestClientGetError, setRequestClientGetMessage,
    setRequestHidrometroGetData, setRequestHidrometroGetLoading, setRequestHidrometroGetError, setRequestHidrometroGetMessage,
    setRequestData, setRequestLoading, setRequestError, setRequestMessage,
    setRequestGetCategoriaData, setRequestGetCategoriaLoading, setRequestGetCategoriaError, setRequestGetCategoriaMessage,
    setRequestGetHoraFinalData, setRequestGetHoraFinalLoading, setRequestGetHoraFinalError, setRequestGetHoraFinalMessage,
    setRequestEditData, setRequestEditLoading, setRequestEditError, setRequestEditMessage,
    setRequestRemoveData, setRequestRemoveLoading, setRequestRemoveError, setRequestRemoveMessage,
    setRequestFilterData, setRequestFilterLoading, setRequestFilterError, setRequestFilterMessage,
    setRequestFilterMedidorData, setRequestFilterMedidorLoading, setRequestFilterMedidorError, setRequestFilterMedidorMessage,
    setLoadingListaVazao,
    setLista, setListaClientes, setListaHidrometros, setFieldFiltered, setListaFiltered, setListaMedidorFiltered, setCurrentLancamento, setFieldFilteredMes, setFieldFilteredFonte,
    setFieldFilteredAno, setEditValues, setCategorias, setListaVazao, setCurrentCategoria, setFieldFilteredMedidor
} = VazaoLancamentoSlice.actions

export default VazaoLancamentoSlice.reducer