import { DOMAIN } from "../../../../../var_env."
import { vazaoLancamento } from "../types"
import { RequestResponsePattern } from "../../../../../types/Request"
import { setListaFiltered, setRequestFilterData, setRequestFilterError, setRequestFilterLoading, setRequestFilterMessage } from "../Reducers/VazaoLancamentoReducer"
import { getApi } from "../../../../../utils/requestApi"

export default async function filterLancamentos(dispatch: Function, values: any){
    try {
        dispatch(setRequestFilterLoading())
        dispatch(setListaFiltered(null))
        const endpoint = `${DOMAIN}/PessoaHidrometroLancamento`
        const params = `/?idMes=${values.idMes}&idAno=${values.idAno}`
        const response: RequestResponsePattern<vazaoLancamento[]> = await getApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestFilterData(response))
            if(!response.flagErro) dispatch(setListaFiltered(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {

        dispatch(setRequestFilterMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestFilterError())
		
    }
}