import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { setListaSeq, setRequestGetData, setRequestGetError, setRequestGetLoading, setRequestGetMessage } from "../Reducers/RelatorioSequencialReducer"
import { getApi } from "../../../../../utils/requestApi"
import { Sequencia } from "../types"



export default async function filterSequenciais(dispatch: Function, busca: string | null, flagOrdenar: string | null) {
    try {
        dispatch(setRequestGetLoading())
        dispatch(setListaSeq([]))
        const endpoint = DOMAIN
        const params = `/RelatorioSequencial/Todos/?pesquisa=${busca??''}&flagOrdernarNome=${flagOrdenar}`
        const response: RequestResponsePattern<Sequencia[]> = await getApi({ endpoint, params })

        if (response) {
            dispatch(setRequestGetData(response))
            if (!response.flagErro) {
                dispatch(setListaSeq(response.listaResultados))

            } else throw new Error(response.listaMensagens[0])
        } else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetError())
    }
}