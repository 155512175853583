import { DOMAIN } from "../../../../../var_env."
import * as R from "../Reducers/VazaoLancamentoReducer"
import { MedidorVazao } from "../types"
import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"

export default async function filter(dispatch: Function, values: any) {
    try {
        dispatch(R.setRequestFilterMedidorLoading())
        dispatch(R.setListaMedidorFiltered(null))

        const endpoint = `${DOMAIN}/PessoaHidrometro`
        const params = `/?nomeCliente=${values.nomeCliente}&idMes=${values.idMes}&idAno=${values.idAno}&medidorVazao=${values.flagMedidorVazao === 'null' ? '' : values.flagMedidorVazao}`
        const response: RequestResponsePattern<MedidorVazao[]> = await getApi({ endpoint, params })

        if (response) {
            dispatch(R.setRequestFilterMedidorData(response))
            if (!response.flagErro) {
                dispatch(R.setListaMedidorFiltered(response.listaResultados))
                localStorage.setItem('anoMes', JSON.stringify({ mes: values.idMes, ano: values.idAno }))
            } else throw new Error(response.listaMensagens[0])
        } else throw new Error('Erro na requisição')
    } catch (error: any) {
        dispatch(R.setRequestFilterMedidorMessage(error.message ?? JSON.stringify(error)))
        dispatch(R.setRequestFilterMedidorError())
    }
}